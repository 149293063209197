import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddColocDemande from '../components/coloc/demandeColoc/AddColocDemande';
import DemandeList from '../components/coloc/demandeColoc/DemandeList';
import DemandeListWithFilter from '../components/coloc/demandeColoc/DemandeListWithFilter';
import AddColocOffre from '../components/coloc/offreColoc/AddColocOffre';
import ColocList from '../components/coloc/offreColoc/ColocList';
import ColocListWithFilter from '../components/coloc/offreColoc/ColocListWithFilter';
import Layout from '../pages/Layout';
import Profile from '../pages/profile/Profile';
// import Abonnement from '../pages/Abonnement';
import TelephoneVerification from '../components/profile/TelephoneVerification';
import AddReview from '../components/reviews/add-review/AddReview';
import AboutPage from '../pages/about/AboutPage';
import AuthLayout from '../pages/auth/AuthLayout';
import LoginPage from '../pages/auth/login/LoginPage';
import RegisterPage from '../pages/auth/register/RegisterPage';
import ColocDetails from '../pages/coloc-details/ColocDetails';
import DemandeDetails from '../pages/coloc/DemandeDetails';
import OffreDetails from '../pages/coloc/OffreDetails';
import HomePage from '../pages/homePage';
import NotFoundPage from '../pages/not-found/NotFoundPage';
import TermsAndPolicies from '../pages/policies/PoliciesPage';
import Annonces from '../pages/profile/Annonces';
import MesOffres from '../pages/profile/MesOffres';
import ProfileDemandeForm from '../pages/profile/ProfileDemandeForm';
import ProfileDemandes from '../pages/profile/ProfileDemandes';
import ProfileInfosWithFormValidation from '../pages/profile/ProfileInfosWithFormValidation';
import UserProfilePage from '../pages/user/UserProfilePage';
import { getAllDemandes, getAllOffres, getDemandesByVille, getOffresByVille } from '../services/apis';
import PrivateRoute from './PrivateRoute';
import ForgetPasswordPage from '../pages/auth/forgetPassword/ForgetPassword';
import ResetPassword from '../pages/auth/resetPassword/ResetPassword';
import Conditions from '../pages/policies/Conditions';
import EditOffre from '../pages/profile/EditOffre';
import EmailVerification from '../pages/auth/emailVerification/EmailVerification';

const AppRoutes = () => (
  <Routes>
    <Route path="/auth" element={<AuthLayout />}>
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="mot-de-passe-oublier" element={<ForgetPasswordPage/>}/>
      <Route path="changer-mot-de-passe/:token" element={<ResetPassword/>}/>
      <Route path="verifier/:token" element={<EmailVerification/>}/>
    </Route>

    {/* Main Layout with Protected Routes */}
    <Route path="/" element={<Layout />}>

      {/* Profile Routes */}
      <Route path="profile/" element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="edit-profile" element={<PrivateRoute><ProfileInfosWithFormValidation /></PrivateRoute>} />
      <Route path="edit-demande/:demandeId" element={<PrivateRoute><ProfileDemandeForm /></PrivateRoute>} />
      <Route path="profile/telephone-verification" element={<PrivateRoute><TelephoneVerification /></PrivateRoute>} />
      <Route path="reservations" element={<PrivateRoute><ProfileDemandes /></PrivateRoute>} />
      <Route path="offres" element={<PrivateRoute><MesOffres /></PrivateRoute>} />
      <Route path="offres/:id" element={<PrivateRoute><EditOffre /></PrivateRoute>} />
      <Route path="annonces" element={<PrivateRoute><Annonces /></PrivateRoute>} />
      <Route path="utilisateur/:id" element={<UserProfilePage />} />

      {/* Coloc Routes */}
      <Route path="ajouter-offre-coloc" element={<PrivateRoute><AddColocOffre /></PrivateRoute>} />
      <Route path="ajouter-demande-coloc" element={<PrivateRoute><AddColocDemande /></PrivateRoute>} />
      {/* <Route path="coloc/ville/:villeId" element={<ColocList getLogements={getColocsByVilleAndType} />} /> */}
      <Route path="coloc/ville/:villeId/offres" element={<ColocList getLogementsApi={(page, size, villeId) => getOffresByVille(page, size, villeId)} title="Offres" />} />
      <Route path="coloc/ville/:villeId/demandes" element={<DemandeList getLogementsApi={(page, size, villeId) => getDemandesByVille(page, size, villeId)} title="Demandes" />} />
      <Route path="coloc/offres" element={<ColocListWithFilter getLogementsApi={(page, size) => getAllOffres(page, size)} title="Offres Filter" subtitle="Explorez toutes les offres de colocation publiées, avec la possibilité de filtrer par ville." />} />
      <Route path="coloc/demandes" element={<DemandeListWithFilter getLogementsApi={(page, size) => getAllDemandes(page, size)} title="Demandes" subtitle="Explorez toutes les demandes de colocation publiées, avec la possibilité de filtrer par ville."/>} />
      <Route path="coloc/:id" element={<ColocDetails />} />

      {/* homepage Routes */}
      <Route path="" element={<HomePage />} />
      <Route path="/conditions" element={<Conditions />} />
      {/* <Route path="abonnement" element={<PrivateRoute><Abonnement /></PrivateRoute>} /> */}
      <Route path="add-review" element={<PrivateRoute><AddReview /></PrivateRoute>} />
      <Route path="demande/:id" element={<DemandeDetails />} />
      <Route path="offre/:id" element={<OffreDetails />} />

      {/* Terms and policies */}
      <Route path="policies" element={<TermsAndPolicies />} />
      <Route path="about" element={<AboutPage />} />
      {/*Catch-All Route */}
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default AppRoutes;
