import React from 'react';

const HeroSection = () => (
  <section id="home" className="welcome-hero">
    <div className="container">
      <div className="welcome-hero-txt">
        <h2>Meilleur endroit pour proposer, chercher et trouver une Colocation <br /> De tout type </h2>
      </div>
      {/* <div className="welcome-hero-serch-box">
        <div className="welcome-hero-form">
          <div className="single-welcome-hero-form">
            <h3>Catégorie ?</h3>
            <form action="index.html">
              <input type="text" placeholder="Ex: STudio, Villa, Appartement" />
            </form>
            <div className="welcome-hero-form-icon">
              <i className="flaticon-list-with-dots"></i>
            </div>
          </div>
          <div className="single-welcome-hero-form">
            <h3>Ville</h3>
            <form action="index.html">
              <input type="text" placeholder="Ex: Marrakech, Casa, Rabat" />
            </form>
            <div className="welcome-hero-form-icon">
              <i className="flaticon-gps-fixed-indicator"></i>
            </div>
          </div>
        </div>
        <div className="welcome-hero-serch">
          <button className="welcome-hero-btn" onClick={() => window.location.href='#'}>search <i data-feather="search"></i></button>
        </div>
      </div> */}
    </div>
  </section>
);

export default HeroSection;
