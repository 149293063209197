import React from "react";
import "./CardDemandeRecu.css";
import { useNavigate } from "react-router-dom";

const CardDemandeRecu = ({ demande }) => {
  const { id, demandeur, dateDemande, logement, statut } = demande;
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/demande/${id}`);
  };

  return (
    <div onClick={handleCardClick} className="card-demand-recu">
      <img src={demandeur?.photo} alt="Avatar" />
      <p className="cookieHeading">
        {demandeur?.nom} {demandeur?.prenom}
      </p>
      <p className="cookieDescription"> {dateDemande} </p>
      <p className="cookieDescription">
        <span>
          {logement?.adresse} {logement?.ville}
        </span>
        <span>{logement?.logementType}</span>
        {/* <span>{message}</span> */}
      </p>
      <p>
        <strong>Statut:</strong>{" "}
        <span className="verification-status">{statut}</span>
      </p>
      <div className="buttonContainer">
        <button className="acceptButton">Accepter</button>
        <button className="declineButton">Annuler</button>
      </div>
    </div>
  );
};

export default CardDemandeRecu;
