import { Building2, Calendar, Clock } from "lucide-react";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { viewImage } from "../../../../services/apis";
import { truncate } from "../../../../utils/stringUtils";
import { timeAgo } from "../../../../utils/timeUtils"; // Import the timeAgo function
import "./index.css";
import { IMAGES } from "../../../../constants/images";

const ColocItemOffre = ({
  id,
  titre,
  description,
  prix,
  superficie,
  nbrColoc,
  type,
  ville,
  quartier,
  proprietaire,
  photos,
  dateDisponibilite,
  duree,
  statut,
  pageSource,
  createdAt,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const imageUrlRef = useRef("");
  const navigate = useNavigate();

  const defaultBg = IMAGES.defaultOffreImage; // Use the defaultOffreImage from IMAGES

  const fetchImage = useCallback(async () => {
    if (photos && photos.length > 0) {
      try {
        const blob = await viewImage(photos[0].src);
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
        imageUrlRef.current = url;
      } catch (error) {
        console.error("Error fetching image:", error);
        setImageUrl(defaultBg);
        imageUrlRef.current = defaultBg;
      }
    } else {
      setImageUrl(defaultBg);
      imageUrlRef.current = defaultBg;
    }
  }, [photos, defaultBg]);

  useEffect(() => {
    fetchImage();

    return () => {
      if (imageUrlRef.current && imageUrlRef.current !== defaultBg) {
        URL.revokeObjectURL(imageUrlRef.current);
      }
    };
  }, [fetchImage, defaultBg]);

  const handleItemClick = () => {
    navigate(`/coloc/${id}`);
  };

  return (
    <>
      <div onClick={handleItemClick} className="card-offre-list">
        <article className="card-offre">
          <div className="card-offre-date-wrapper">
            <div className="card-offre-person">
              <div className="card-offre-person-initial">
                {proprietaire?.nom
                  ? proprietaire.nom.charAt(0).toUpperCase()
                  : "N"}
              </div>
              <p>{proprietaire?.nom || "Nom"}</p>
            </div>
            <div className="card-offre-date">
              <Clock size={14} />
              <span>
                {createdAt ? timeAgo(createdAt) : "Date non disponible"}
              </span>
            </div>
          </div>
          <figure className="card-offre-image">
            <img
              src={imageUrl || defaultBg}
              alt={titre || "Image par défaut"}
            />
            {/* <div className="image-count">
            <i className="fa fa-images"></i> {photos ? photos.length : 0}
          </div> */}
          </figure>
            <p className="card-offre-image-supplementaire">
              <span>{photos?.length>0?photos?.length - 1:0}</span>
              <span>{"Images supplementaires"}</span>
            </p>
          <div className="card-offre-header">
            <h1>{truncate(titre, 25)}</h1>
          </div>
          {/* <hr /> */}
          <div className="card-offre-footer">
            <div className="card-offre-meta">
              <div className="card-offre-meta-item">
                <Calendar size={20} />{" "}
                {dateDisponibilite === "2000-01-01"
                  ? "Immédiatement disponible"
                  : dateDisponibilite}
              </div>
              <div className="card-offre-meta-item">
                <Building2 size={20} /> {ville?.nom || "Ville non disponible"}
                {quartier ? `, ${quartier}` : ""}
              </div>
            </div>
            {/* <hr /> */}
            <p className="explore-rating-price">
              <span className="explore-superficie">
                {superficie > 0 && `${superficie} m²`}
                {nbrColoc > 0 && ` / ${nbrColoc} coloc`}
                {superficie === 0 &&
                  nbrColoc === 0 &&
                  "Détails non disponibles"}
              </span>
              <span className="explore-price-box">
                <span className="explore-price">
                  {prix && prix !== "0" ? `${prix} dh` : "Prix non disponible"}
                </span>
              </span>
              <span className="explore-type">
                {type || "Type non disponible"}
              </span>
            </p>
            {pageSource === "profile" && (
              <span className="offre-status">{statut}</span>
            )}
          </div>
        </article>
      </div>
    </>
  );
};

ColocItemOffre.propTypes = {
  id: PropTypes.number.isRequired,
  titre: PropTypes.string,
  description: PropTypes.string,
  prix: PropTypes.string,
  superficie: PropTypes.number,
  nbrColoc: PropTypes.number,
  type: PropTypes.string,
  ville: PropTypes.shape({
    nom: PropTypes.string,
    image: PropTypes.string,
  }),
  quartier: PropTypes.string,
  proprietaire: PropTypes.shape({
    nom: PropTypes.string,
    isVerified: PropTypes.bool,
  }),
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
    })
  ),
  dateDisponibilite: PropTypes.string,
  duree: PropTypes.string,
  statut: PropTypes.string,
  pageSource: PropTypes.string,
  createdAt: PropTypes.string, // Add createdAt to PropTypes
};

ColocItemOffre.defaultProps = {
  titre: "Titre non disponible",
  description: "Description non disponible",
  prix: "Prix non disponible",
  superficie: 0,
  nbrColoc: 0,
  type: "Type non disponible",
  ville: { nom: "Ville non disponible", image: "default.jpg" },
  quartier: "Quartier non disponible",
  proprietaire: { nom: "Nom non disponible", isVerified: false },
  photos: [],
  dateDisponibilite: "Date non disponible",
  duree: "Durée non disponible",
  statut: "Status non disponible",
  pageSource: "home",
  createdAt: null, // Set default value for createdAt
};

export default ColocItemOffre;
