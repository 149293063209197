import React, { useState, useEffect, useRef } from "react";
import { User, ChevronDown, FileText, LogOut } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../state/slices/authSlice";

const menuItems = [
  { href: "/profile", label: "Profil", icon: <User size={16} /> },
  { href: "/offres", label: "Mes annonces", icon: <FileText size={16} /> },
];

const UserDropdownMenu = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user); // Get user data from Redux state

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/"); // Redirect to home after logout
  };

  useEffect(() => {
    if (isDropdownOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      if (dropdownRect.bottom > viewportHeight) {
        const scrollAmount = dropdownRect.bottom - viewportHeight + 10; // Add a small buffer
        window.scrollTo({
          top: window.scrollY + scrollAmount,
          behavior: "smooth",
        });
      }
    }
  }, [isDropdownOpen]);

  return (
    <div style={{ position: "relative", marginRight:"10px" }}>
      <button
        onClick={toggleDropdown}
        className="dropdown-btn"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "6px 12px",
          backgroundColor: "#f0f0f0",
          borderRadius: "20px",
          cursor: "pointer",
        }}
      >
        <User size={20} />
        <span style={{ margin: "0 10px" }}>{user ? user.username : "User"}</span>
        <ChevronDown size={16} />
      </button>
      {isDropdownOpen && (
        <ul
          ref={dropdownRef}
          style={{
            position: "absolute",
            right: 0,
            top: "100%",
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
            listStyleType: "none",
            padding: 0,
            margin: "5px 0 0 0",
            minWidth: "200px",
            zIndex: 999,
          }}
        >
          {menuItems.map((item, index) => (
            <li key={index}>
              <Link
                to={item.href}
                className="dropdown-user-item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 20px",
                  color: "#333",
                  textDecoration: "none",
                }}
              >
                {item.icon}
                <span style={{ marginLeft: "10px" }}>{item.label}</span>
              </Link>
            </li>
          ))}
          {/* Add Logout Button */}
          <li>
            <button
              onClick={handleLogout}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 20px",
                width: "100%",
                backgroundColor: "transparent",
                border: "none",
                color: "#333",
                cursor: "pointer",
              }}
            >
              <LogOut size={16} />
              <span style={{ marginLeft: "10px" }}>Se déconnecter</span>
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default UserDropdownMenu;
