import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./PoliciesPage.css";


const TermsAndPolicies = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const formData = location.state?.formData;

  console.log("formData", formData);

  const handleGoBack = () => {
    if (formData) {
      navigate("/auth/register", { state: { formData } });
    } else {

      navigate(-1);
    }
  };
  return (
    <div className="terms-container">
      <h1 className="terms-title">Charte de confidentialité</h1>
      <div className="terms-content">
        <p>
          Sur colocky.ma, nous nous engageons à protéger la confidentialité de
          vos données personnelles. Nous vous expliquons à ce niveau comment
          nous collectons, utilisons et protégeons vos informations.
        </p>

        <p>
          <h3>Collecte des données</h3>
          <br />
          Nous collectons les informations suivantes : nom, adresse e-mail,
          adresse IP, et autres données que vous choisissez de fournir. Ces
          données sont collectées lorsque vous remplissez des formulaires
          d'inscription en ligne ou utilisez notre site pour les raisons et
          services que vous propose colocky.ma.
        </p>

        <p>
          <h3>Utilisation des données</h3>
          <br />
          Les informations collectées sont utilisées pour améliorer les
          services de notre site, vous envoyer des informations pertinentes et
          répondre à vos demandes. Nous traitons vos données sur la base de
          votre consentement ou pour faciliter votre contact par les
          utilisateurs désireux pour répondre à un service de ceux offerts par
          notre site.
        </p>

        <p>
          <h3>Partage des données</h3>
          <br />
          Nous ne partageons pas vos données personnelles avec des tiers, sauf
          avec nos partenaires de confiance qui nous aident à fournir nos
          services. Vos données ne sont pas transférées en dehors des
          utilisateurs soumis à la même charte.
        </p>

        <p>
          <h3>Sécurité des données</h3>
          <br />
          Nous avons mis en place des mesures de sécurité techniques et
          organisationnelles pour protéger vos données personnelles contre tout
          accès non autorisé et leur divulgation.
        </p>

        <p>
          <h3>Conservation des données</h3>
          <br />
          Nous conservons vos données personnelles aussi longtemps que
          nécessaire pour atteindre les objectifs pour lesquels elles ont été
          collectées, conformément à la législation en vigueur.
        </p>

        <p>
          <h3>Droits des utilisateurs</h3>
          <br />
          Vous avez le droit d'accéder, de rectifier ou de supprimer vos données
          personnelles. Pour exercer ces droits, veuillez nous contacter à{" "}
          <a href="mailto:contact@colocky.ma">contact@colocky.ma</a>.
        </p>

        <p>
          <h3>Cookies et technologies similaires</h3>
          <br />
          Nous pouvons utiliser des cookies pour améliorer votre expérience sur
          notre site. Vous pouvez gérer vos préférences en matière de cookies
          via les paramètres de votre navigateur.
        </p>

        <p>
          <h3>Modifications de la charte</h3>
          <br />
          Nous pouvons modifier ou retirer des parties de cette charte de
          confidentialité à tout moment. Toute modification sera publiée sur
          cette page, vous êtes amenés à la consulter périodiquement, afin de
          vous tenir à jour, en cas d’éventuels changements.
        </p>

        <p>
          <h3>Contact</h3>
          <br />
          Pour toute question concernant cette charte de confidentialité,
          veuillez nous contacter à{" "}
          <a href="mailto:contact@colocky.ma">contact@colocky.ma</a>.
        </p>

      </div>
      <div className="terms-buttons">
        <button onClick={handleGoBack} className="terms-button decline">
          Accept
        </button>
        {/* <button className="terms-button accept">Accept</button> */}
      </div>
    </div>
  );
};

export default TermsAndPolicies;
