import React from "react";
import { User, Edit, Calendar, Briefcase } from "lucide-react";
import { useSelector } from "react-redux";
import "./ProfileNav.css";

const ProfileNav = ({ profileData, handleNavigation }) => {
  const demandeFilled = useSelector((state) => state.auth.demandeFilled);


  // Check if profileData.offres is an array and has at least one item
  const hasOffers = Array.isArray(profileData.offres) && profileData.offres.length > 0;

  const navItems = [
    { path: "/profile", icon: User, text: "Profil", active: true },
    { path: "/edit-profile", icon: Edit, text: "Modifier votre profil" },
    demandeFilled && {
      path: "/edit-demande/" + (profileData.userInfo?.demande?.id || ''),
      icon: Calendar,
      text: "Modifier votre demande",
    },
    hasOffers && {
      path: "/offres",
      icon: Briefcase,
      text: "Gérer vos offres",
      badge: profileData.offres.length,
    },
  ].filter(Boolean);


  return (
    <aside className="profile-nav">
      <div className="user-info">
        <div className="user-avatar">
          {profileData.userInfo?.nom
            ? profileData.userInfo.nom.charAt(0).toUpperCase()
            : "N"}
        </div>
        <h1 className="user-name">
          {profileData.userInfo?.nom || "N/A"}{" "}
          {profileData.userInfo?.prenom || "N/A"}
        </h1>
        <p className="user-email">{profileData.userInfo?.email}</p>
      </div>
      <nav className="nav-menu">
        {navItems.map((item, index) => (
          <button
            key={index}
            onClick={() => handleNavigation(item.path)}
            className={`nav-item ${item.active ? "active" : ""}`}
          >
            <item.icon className="nav-icon" />
            <span>{item.text}</span>
            {item.badge !== undefined && (
              <span className="badge">{item.badge}</span>
            )}
          </button>
        ))}
      </nav>
    </aside>
  );
};

export default ProfileNav;
