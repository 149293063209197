import logo from '../assets/images/logo.png';
import leftLoginImage from '../assets/images/auth/login-image.jpg';
import leftRegisterImage from '../assets/images/auth/register-image.jpg';
import aboutImage from '../assets/images/about/about.jpg'
import defaultOffreImage from '../assets/images/default-offre-img.png';

export const IMAGES = {
    logo,
    leftLoginImage,
    leftRegisterImage,
    aboutImage, defaultOffreImage
};
