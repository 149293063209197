import React, { useEffect, useState } from "react";
import "../../assets/css/profile.css";
import CardDemandeEnvoye from "../../components/card-demande-envoye/CardDemandeEnvoye";
import CardDemandeRecu from "../../components/card-demande-recu/CardDemandeRecu";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { Tab, TabList, TabPanel, Tabs } from "../../components/tabs/Tabs";
import { getDemandesByUserId } from "../../services/apis";
import { useSelector } from "react-redux";
import BackButton from "../../components/BackButton";

export const ProfileDemandes = () => {
  const [demandes, setDemandes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const response = await getDemandesByUserId(user?.id);
        console.log(response.data);
        setDemandes(response.data);
        setLoading(false);
      } catch (err) {
        setError("Error fetching demandes");
        setLoading(false);
      }
    };

    fetchDemandes();
  }, [user?.id]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="coloc-form">{error}</div>;

  return (
    <div className="coloc-form">
       <BackButton path="/profile" />
      <h2 className="text-left formTitle ">Mes demandes</h2>
      <div className="App">
        <Tabs defaultIndex={0}>
          <TabList>
            <Tab index={0}>Demandes Recues</Tab>
            <Tab index={1}>Demandes Envoyées</Tab>
          </TabList>
          <TabPanel index={0}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              {demandes && demandes.map((demande, index) => (
                <CardDemandeRecu  demande={demande} key={index} />
              ))}
            </div>
          </TabPanel>
          <TabPanel index={1}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              {demandes && demandes.map((demande, index) => (
                <CardDemandeEnvoye demande={demande} key={index} />
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </div>

      {/* <section className="nice-form-group">
        <div className="demandes-list">
          {demandes.map((demande) => (
            <div key={demande.id} className="demande-item">
              <h2>{demande.logement?.adresse || "Logement"}</h2>
              <p>
                <strong>Proprietaire:</strong>{" "}
                {demande.logement.proprietaire?.email}
              </p>
              <p>
                <strong>Prix:</strong> {demande.logement.prix} (MAD)
              </p>
              <p>
                <strong>Date de demande:</strong>{" "}
                {new Date(demande.dateDemande).toLocaleDateString()}
              </p>
              <p>
                <strong>Statut:</strong>{" "}
                <span className="verification-status">{demande.statut}</span>
              </p>
              <a href={`/demande/${demande.id}`} className="details-link">
                Voir les détails
              </a>
            </div>
          ))}
        </div>
      </section> */}
    </div>
  );
};
export default ProfileDemandes;
