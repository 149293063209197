import { useNavigate } from "react-router-dom";

const BackButton = ({ path }) => {
  const navigate = useNavigate();

  const handleProfileBack = () => {
    navigate(path);
  }

  return (
    <button onClick={handleProfileBack} className="btn default">
      <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </button>
  );
};

export default BackButton;
