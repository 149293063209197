import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUtilisateur } from "../../services/apis";
import "./ProfileCard.css";
// import {
//   FaMapMarkerAlt,
//   FaBuilding,
//   FaWallet,
//   FaCalendar,
// } from "react-icons/fa";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import ShowNumberModal from "./show-number-modal/ShowNumberModal";
import DemandeLogementInfo from "./demande-logement/DemandeLogementInfo";
// import DemandDetailsCard from "../profile/profile-demand-details/DemandDetailsCard";

const UserProfilePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await getUtilisateur(id);
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user data. Please try again later.");
      }
    };

    fetchUser();
  }, [id]);

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!user) {
    return <LoadingSpinner />;
  }

  const handleContactDisplay = () => {
    if (user) {
      setShowPopup(true);
    } else {
      navigate("/auth");
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div
      style={{ fontFamily: "Poppins, sans-serif" }}
      className="container-fluid profile-card"
    >
      <div className="row">
        <div className="col-md-6">
          <header className="profile-header">
            <div className="user-avatar">
              {user?.nom ? user.nom.charAt(0).toUpperCase() : "N"}
            </div>

            <div className="profile-info">
              <h1>{user.username || "Username not available"}</h1>
              <p>{user.age ? `${user.age} ans` : "Âge non spécifié"}</p>
              <div className="verification">
                <span
                  className={`verified ${
                    user.identityVerified ? "active" : ""
                  }`}
                >
                  Identité vérifiée
                </span>
                <span
                  className={`verified ${user.emailVerified ? "active" : ""}`}
                >
                  E-mail vérifié
                </span>
                <span
                  className={`verified ${user.telVerified ? "active" : ""}`}
                >
                  Téléphone vérifié
                </span>
              </div>
            </div>
            <button className="contact-btn" onClick={handleContactDisplay}>
              Afficher le numéro de téléphone
            </button>
          </header>

          <section className="about">
            <h2>À propos:</h2>
            <p>{user.apropos || "Aucune information disponible."}</p>
          </section>

          <section className="languages">
            <h2>Langues:</h2>
            <ul>
              {user.langues && user.langues.length > 0 ? (
                user.langues.map((lang, index) => <li key={index}>{lang}</li>)
              ) : (
                <li>Aucune langue spécifiée.</li>
              )}
            </ul>
          </section>
        </div>

        <div className="col-md-6">
          <DemandeLogementInfo user={user} />
          {/* <DemandDetailsCard demandeData={user.demande} /> */}
          <section
            style={{marginTop:'15px'}}
          className="interests">
            <h2>Intérêts:</h2>
            <div className="interest-group">
              <div className="tags">
                {user.tags && user.tags.length > 0 ? (
                  user.tags.map((item, index) => (
                    <span key={index} className="tag">
                      {item}
                    </span>
                  ))
                ) : (
                  <span>Aucun intérêt spécifié.</span>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      {showPopup && (
        <ShowNumberModal
          isOpen={showPopup}
          onClose={handleClosePopup}
          description={
            "Vérifiez bien le colocataire potentiel avant de partager des informations ou de faire un paiement. Rencontrez-le d'abord en personne."
          }
          sousDescription={"Appelez pour organiser une rencontre."}
          title={"Important !"}
          phoneNumber={user.telephone || "Numéro non disponible"}
        />
      )}
    </div>
  );
};

export default UserProfilePage;
