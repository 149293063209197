import React from 'react';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Custom validation messages in French
const schema = yup.object().shape({
  titre: yup.string().required('Le titre est requis'),
  quartier: yup.string().required('Le quartier est requis'),
  description: yup.string().required('La description est requise'),
  prix: yup
    .number()
    .typeError('Le prix doit être un nombre')
    .positive('Le prix doit être positif')
    .required('Le prix est requis'),
  superficie: yup
    .number()
    .typeError('La superficie doit être un nombre')
    .positive('La superficie doit être positive')
    .required('La superficie est requise'),
  nbrColoc: yup
    .number()
    .typeError('Le nombre de colocataires doit être un nombre entier')
    .integer('Le nombre de colocataires doit être un entier')
    .min(1, 'Au moins un colocataire est requis')
    .required('Le nombre de colocataires est requis'),
  logementType: yup.string().required('Le type de logement est requis'),
  villeId: yup.string().required('La ville est requise'),
  dateDisponibilite: yup.string().required('La date de disponibilité est requise')
});

function StepTwoLogementForm({ formData, handleChange, handleCityChange, previousStep, nextStep, typesDeLogement, villes }) {
  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formData
  });

  const watchDateDisponibilite = watch('dateDisponibilite');

  const onSubmit = (data) => {
    Object.keys(data).forEach(key => {
      handleChange({ target: { name: key, value: data[key] } });
    });
    nextStep();
  };

  return (
    <Container className='formContainer'>
      <h2 className='formTitle'>Détails du Logement</h2>
      <Row>
        <Col md={{ span: 12, offset: 3 }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* Titre */}
            <Form.Group className="formGroup">
              <Form.Label>Titre D'Annonce</Form.Label>
              <Form.Control
                placeholder="Exemple : Dispose d'un studio ..."
                type="text"
                {...register('titre')}
                isInvalid={!!errors.titre}
              />
              <Form.Control.Feedback type="invalid">
                {errors.titre?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Description */}
            <Form.Group className="formGroup">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Décrivez brièvement le logement"
                {...register('description')}
                isInvalid={!!errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Ville */}
            <Form.Group className="formGroup">
              <Form.Label>Ville</Form.Label>
              <Form.Control
                as="select"
                {...register('villeId')}
                onChange={(e) => {
                  handleCityChange(e);
                  register('villeId').onChange(e);
                }}
                isInvalid={!!errors.villeId}
              >
                <option value="">Sélectionner une ville</option>
                {villes.map((ville) => (
                  <option key={ville.id} value={ville.id}>{ville.nom}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.villeId?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Quartier */}
            <Form.Group className="formGroup">
              <Form.Label>Quartier</Form.Label>
              <Form.Control
                type="text"
                placeholder="Saisissez le quartier"
                {...register('quartier')}
                isInvalid={!!errors.quartier}
              />
              <Form.Control.Feedback type="invalid">
                {errors.quartier?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Prix */}
            <Form.Group className="formGroup">
              <Form.Label>Prix (DH)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Saisissez le prix"
                {...register('prix')}
                isInvalid={!!errors.prix}
              />
              <Form.Control.Feedback type="invalid">
                {errors.prix?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Superficie */}
            <Form.Group className="formGroup">
              <Form.Label>Superficie (m²)</Form.Label>
              <Form.Control
                type="number"
                placeholder='En m²'
                {...register('superficie')}
                isInvalid={!!errors.superficie}
              />
              <Form.Control.Feedback type="invalid">
                {errors.superficie?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Nombre de colocataires */}
            <Form.Group className="formGroup">
              <Form.Label>Nombre de colocataires souhaités</Form.Label>
              <Form.Control
                type="number"
                placeholder="Saisissez le nombre de colocataires"
                {...register('nbrColoc')}
                isInvalid={!!errors.nbrColoc}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nbrColoc?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Type de logement */}
            <Form.Group className="formGroup">
              <Form.Label>Type de logement</Form.Label>
              <Form.Control
                as="select"
                {...register('logementType')}
                isInvalid={!!errors.logementType}
              >
                <option value="">Sélectionner un type</option>
                {typesDeLogement.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.logementType?.message}
              </Form.Control.Feedback>
            </Form.Group>

            {/* Disponibilité */}
            <Form.Group className="formGroup">
              <Form.Label>Disponibilité</Form.Label>
              <Form.Check
                type="radio"
                label="Immédiatement disponible"
                value="IMMEDIATE"
                {...register('dateDisponibilite')}
              />
              <Form.Check
                type="radio"
                label="Disponible à partir d'une date spécifique"
                value="SPECIFIC"
                {...register('dateDisponibilite')}
              />
            </Form.Group>

            {/* Date de disponibilité */}
            {watchDateDisponibilite === 'SPECIFIC' && (
              <Form.Group className="formGroup">
                <Form.Label>Date de disponibilité</Form.Label>
                <Form.Control
                  type="date"
                  {...register('dateDisponibilite')}
                  isInvalid={!!errors.dateDisponibilite}
                  min={new Date().toISOString().split('T')[0]} // Prevent past dates
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dateDisponibilite?.message}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {/* Buttons */}
            <Form.Group className="formGroup d-flex justify-content-between">
              <Button className="default" type="button" onClick={previousStep}>Précédent</Button>
              <Button className="default" type="submit">Suivant</Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default StepTwoLogementForm;
