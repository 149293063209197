import React, { useEffect } from 'react';
import './assets/css/style.css';
import './assets/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css'; 
import './assets/css/bootsnav.css';
import './assets/css/animate.css';
import './assets/css/slick.css';
import './assets/css/slick-theme.css';
import './assets/css/font-awesome.min.css';
import './assets/css/linearicons.css';
import './assets/css/c.css';
import './assets/css/navigation.css'


import { useDispatch } from 'react-redux';
// import { fetchColocations } from './state/slices/colocSlice';
import { fetchGeneralData } from './state/slices/generalDataSlice';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { clearStorageIfExpired } from './utils/localStorageUtils';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGeneralData());
  }, [dispatch]);

  useEffect(() => {
    clearStorageIfExpired();
  }, []);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
