import { api } from './axiosConfig';

// Ville Controller
export const getVille = (id) => api.get(`/test/ville/${id}`);
export const updateVille = (id, data) => api.put(`/test/ville/${id}`, data);
export const deleteVille = (id) => api.delete(`/test/ville/${id}`);
export const getAllVilles = () => api.get(`/test/ville`,{params:{page:0,size:200}});
export const createVille = (data) => api.post(`/test/ville`, data);

// Utilisateur Controller
export const getUtilisateur = (id) => api.get(`/test/utilisateur/${id}`);
export const updateUtilisateur = (id, data) => api.put(`/test/utilisateur/${id}`, data);
export const deleteUtilisateur = (id) => api.delete(`/test/utilisateur/${id}`);
export const getAllUtilisateurs = (page,size) => api.get(`/test/utilisateur`,{ params: { page, size } });
export const getTelephoneById = (id, userId) => api.post(`/test/utilisateur/tele?id=${encodeURIComponent(id)}&userId=${encodeURIComponent(userId)}`);


// Photo Controller that store paths in db
export const getPhoto = (id) => api.get(`/test/photo/${id}`);
export const updatePhoto = (id, data) => api.put(`/test/photo/${id}`, data);
export const deletePhoto = (id) => api.delete(`/test/photo/${id}`);
export const getAllPhotos = () => api.get(`/test/photo`,{params:{page:0,size:200}});
export const createPhoto = (data) => api.post(`/test/photo`, data);

// Image Controller that upload and fetch image to and from the server
export const uploadImage = (data) => api.post(`/test/images/upload`, data);
export const viewImage = async (filename) => {
    try {
      const response = await api.get(`/test/images/view/${filename}`, {
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching image as blob:', error);
      throw error;
    }
  };




  export const getAllOffres = (page, size) => 
    api.get(`/test/coloc/search`, { params: { page, size, verified: true } });
  export const getOffresByVille = (page, size, villeId) => 
    api.get(`/test/coloc/search`, { params: { page, size, verified: true, villeId } });
  export const getOffresByUserId = (page, size, userId) => 
    api.get(`/test/coloc/search`, { params: { page, size, userId } });
  export const marquerCommeLouee = (id) => 
    api.patch(`/test/coloc/statut/loue/${id}`);
  export const marquerCommeDisponible = (id) => 
    api.patch(`/test/coloc/statut/disponible/${id}`); 
  export const createOffre = (data) => api.post(`/test/coloc`, data);
  export const getOffre = (id) => api.get(`/test/coloc/${id}`);
  export const updateOffre = (id, data) => api.put(`/test/coloc/${id}`, data);
  export const deleteOffre = (id) => api.delete(`/test/coloc/${id}`);


  export const getAllDemandes = (page,size) => 
    api.get(`/test/demande/search`, { params: { page, size, verified: true } });
  export const getDemandesByVille = (page, size, villeId) =>
    api.get(`/test/demande/search`, { params: { page, size, verified: true, villeId } });
  export const createDemande = (data) => api.post(`/test/demande`, data);
  export const getDemande = (id) => api.get(`/test/demande/${id}`);
  export const updateDemande = (id,data) => api.put(`/test/demande/${id}`,data);
  export const deleteDemande = (id) => api.delete(`/test/demande/${id}`);
  export const getDemandesByUserId = (id) => api.get(`/test/demande/user/${id}`);


// Demande Colocation Controller
export const getReservation = (id) => api.get(`/test/demande/${id}`);
export const getReservationByIdUtilisateur = (id) => api.get(`/test/demande/utilisateur/${id}`);
export const updateReservation = (id, data) => api.put(`/test/demande/${id}`, data);
export const deleteReservation = (id) => api.delete(`/test/demande/${id}`);
export const getAllReservations = () => api.get(`/test/demande`);
export const createReservation = (data) => api.post(`/test/demande`, data);

// Avis Controller
export const getAvis = (id) => api.get(`/test/avis/${id}`);
export const updateAvis = (id, data) => api.put(`/test/avis/${id}`, data);
export const deleteAvis = (id) => api.delete(`/test/avis/${id}`);
export const getAllAvis = (page,size) => api.get(`/test/avis/search`,{ params: { page, size } });
export const getAllValidAvis = (page=1,size=10,valide=true) => api.get(`/test/avis/search`,{ params: { page, size,valide } });
export const getAvisByIdUtilisateur = (id) => api.get(`/test/avis/utilisateur/${id}`);
export const createAvis = (data) => api.post(`/test/avis`, data);

// Auth Controller
export const signUp = (data) => api.post(`auth/signup`, data);
export const signIn = (data) => api.post(`auth/signin`, data);
export const forgetPassword = (email) => api.post(`/test/utilisateur/forgot-password?email=${encodeURIComponent(email)}`);
export const resetPassword = (token, newPassword) => api.post(`/test/utilisateur/reset-password?token=${encodeURIComponent(token)}&newPassword=${encodeURIComponent(newPassword)}`);
export const verifyEmail = (token) => api.get(`/test/utilisateur/verify?token=${encodeURIComponent(token)}`);





//contact 
export const sendMessage = (data) => api.post(`/test/contact`, data);