import {
  Building,
  Calendar,
  MapPin,
  ReceiptText,
  Text,
  Wallet,
} from "lucide-react";
import React from "react";
import "./DemandeLogementInfo.css";

const DemandeLogementInfo = ({ user }) => {
  if (!user.demande) {
    return (
      <section className="housing-info">
        <h2>Demande de logement:</h2>
        <p>Aucune demande de logement disponible.</p>
      </section>
    );
  }

  const { titre, ville, quartier, budget, dateDisponibilite, description } =
    user.demande;

  const items = [
    { icon: MapPin, label: "Ville", value: ville?.nom || "Non spécifiée" },
    { icon: Building, label: "Quartier", value: quartier || "Non spécifié" },
    { icon: Wallet, label: "Budget", value: budget || "Non spécifié" },
    {
      icon: Calendar,
      label: "Disponibilité",
      value: dateDisponibilite || "Non spécifiée",
    },
    {
      icon: ReceiptText,
      label: "Description",
      value: description || "Aucune description disponible",
    },
  ];

  return (
    <section className="housing-info">
      <h2>Demande de logement</h2>
      <div className="housing-details">
        <h3 className="housing-title">
          <Text size={24} className="" />
          {titre || "Titre non spécifié"}
        </h3>
        <div className="detail-items">
          {items.map((item, index) => (
            <div key={index} className="detail-item">
              <div className="icon-container">
                <item.icon size={24} />
              </div>
              <div className="item-content">
                <span className="item-label">{item.label}</span>
                <span className="item-value">{item.value}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DemandeLogementInfo;
