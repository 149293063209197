import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IMAGES } from "../constants/images";
import SelectionModal from "./modal/SelectionModal";
import Toast from "./toast/Toast.jsx";
import UserDropdownMenu from "./user-dropdown/UserDropdownMenu.jsx";

const navLinks = [
  { path: "home", label: "Accueil" },
  { path: "works", label: "Guide" },
  { path: "explore", label: "Colocations" },
  { path: "reviews", label: "Témoignage" },
  { path: "contact", label: "Contact" },
];

const Navigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showAnnonceModal, setShowAnnonceModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [scrollToId, setScrollToId] = useState(null);

  const handleCloseAnnonceModal = () => {
    setShowAnnonceModal(false);
  };

  const handleShowAnnonceModal = () => {
    if (!auth.profileFilled) {
      // If the profile is not filled, show toast and navigate to profile page
      setShowToast(true);
      setTimeout(() => {
        navigate("/profile");
      }, 2500);
    } else if (auth.demandeFilled) {
      // If the demand is already filled, redirect directly to "ajouter-offre-coloc"
      navigate("/ajouter-offre-coloc");
    } else {
      setShowAnnonceModal(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (location.pathname === "/" && scrollToId) {
        const section = document.getElementById(scrollToId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
        setScrollToId(null);
      }
    }, 3000);
  }, [location, scrollToId]);

  const toggleNavbar = () => {
    setIsNavOpen(!isNavOpen);
  };

  const scrollToSection = (id) => {
    if (location.pathname !== "/") {
      setScrollToId(id);
      navigate("/");
    } else {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <nav
      className="navbar navbar-default bootsnav navbar-sticky navbar-scrollspy"
      // className="navbar navbar-default bootsnav navbar-sticky navbar-scrollspy"
      data-minus-value-desktop="70"
      data-minus-value-mobile="55"
      z
      data-speed="1000"
      style={{
        fontFamily: "Poppins, sans-serif",
        zIndex: 99,
      }}
    >
      <div className="containe navigation"

      >
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" onClick={toggleNavbar}>
            <i className="fa fa-bars"></i>
          </button>
          <Link className="navbarbrand" to="/">
            <img
              src={IMAGES.logo}
              style={{
                width: "80px",
                padding: "0 10px",
                marginTop: "0px",
              }}
              alt="logo"
            />
          </Link>
        </div>
        <div
          style={{
            height: '70vh',// for mobile view
          }}
          className={`collapse navbar-collapse menu-ui-design ${isNavOpen ? "in" : ""
            }`}
          id="navbar-menu"
        >
          <ul
            className="nav navbar-nav navbar-left"
            data-in="fadeInDown"
            data-out="fadeOutUp"
          >
            {navLinks.map((link, index) => (
              <li key={index} className="scroll">
                <a
                  href={`#${link.path}`}
                  style={{ fontSize: "17px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection(link.path);
                  }}
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
          <ul className="nav navbar-nav navbar-right nav-right-flex">
            <li>
              {auth.user ? (
                <UserDropdownMenu />
              ) : (
                <Link to="/auth/login" style={{ fontSize: "17px" }}>
                  Se connecter
                </Link>
              )}
            </li>
            <li>
              <button
                style={{ fontSize: "16px" }}
                className="btn btn-primary navbar-btn"
                onClick={handleShowAnnonceModal}
              >
                Publier une annonce
              </button>
            </li>
          </ul>
        </div>
      </div>
      {showAnnonceModal && (
        <SelectionModal
          isOpen={showAnnonceModal}
          onClose={handleCloseAnnonceModal}
          links={[
            {
              path: "ajouter-offre-coloc",
              label: "Offres",
              title: "Ajouter une offre ",
              icon: "FaSearch",
              description:
                "Vous avez déjà un logement et recherchez des colocataires pour le partager? <br/><b>Publiez votre offre ici</b> pour trouver les personnes idéales pour rejoindre votre espace.",
            },
            {
              path: "ajouter-demande-coloc",
              label: "Demandes",
              title: "Ajouter une demande",
              icon: "FaHome",
              description:
                "Vous cherchez une colocation existante ? <br/><b>Publiez votre demande ici </b> et trouvez le logement qui correspond à vos besoins.",
            },
          ]}
          title="Quel est votre besoin ?"
          description="Choisissez le type d'annonce qui correspond à votre situation."
        />
      )}
      {showToast && (
        <Toast
          title="Profil incomplet"
          desc="Veuillez compléter votre profil avant d'ajouter une annonce."
          delay={2500}
          onClose={() => setShowToast(false)}
        />
      )}
    </nav>
  );
};

export default Navigation;
