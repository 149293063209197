import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getOffre, updateOffre, deleteOffre } from '../../services/apis';
import '../../assets/css/profile.css';

const OffreDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [logement, setOffre] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        adresse: '',
        prix: '',
        superficie: '',
        nbrColoc: '',
        description: '',
        type: '',
        ville: '',
    });

    useEffect(() => {
        const fetchOffre = async () => {
            try {
                const response = await getOffre(id);
                setOffre(response.data);
                setFormData({
                    adresse: response.data.adresse,
                    prix: response.data.prix,
                    superficie: response.data.superficie,
                    nbrColoc: response.data.nbrColoc,
                    description: response.data.description,
                    type: response.data.type,
                    ville: response.data.ville?.nom,
                });
            } catch (error) {
                console.error('Error fetching logement:', error);
            }
        };

        fetchOffre();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleUpdate = async () => {
        try {
            await updateOffre(id, formData);
            setOffre({ ...logement, ...formData });
            setEditMode(false);
        } catch (error) {
            console.error('Error updating logement:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await deleteOffre(id);
            navigate('/mes-offres');
        } catch (error) {
            console.error('Error deleting logement:', error);
        }
    };

    if (!logement) return <p>Loading...</p>;

    return (
        <div className="coloc-form">
            <section className="logement-detail">
                <div className="demo-page-content">
                    {editMode ? (
                        <div className="edit-form">
                            <h2>Modifier Offre</h2>
                            <form>
                                <div className="nice-form-group">
                                    <label>Adresse</label>
                                    <input
                                        type="text"
                                        name="adresse"
                                        value={formData.adresse}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="nice-form-group">
                                    <label>Prix</label>
                                    <input
                                        type="text"
                                        name="prix"
                                        value={formData.prix}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="nice-form-group">
                                    <label>Superficie</label>
                                    <input
                                        type="text"
                                        name="superficie"
                                        value={formData.superficie}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="nice-form-group">
                                    <label>Nombre de colocataires</label>
                                    <input
                                        type="text"
                                        name="nbrColoc"
                                        value={formData.nbrColoc}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="nice-form-group">
                                    <label>Description</label>
                                    <input
                                        type="text"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="nice-form-group">
                                    <label>Type</label>
                                    <input
                                        type="text"
                                        name="type"
                                        value={formData.type}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="nice-form-group">
                                    <label>Ville</label>
                                    <input
                                        type="text"
                                        name="ville"
                                        value={formData.ville}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <button style={{margin:"10px"}} type="button" onClick={handleUpdate} className="to-reset">
                                    Mettre à jour
                                </button>
                            </form>
                        </div>
                    ) : (
                        <div className="logement-info">
                            <h2>Détails Offre</h2>
                            <p><strong>Adresse:</strong> {logement.adresse}</p>
                            <p><strong>Prix:</strong> {logement.prix} (MAD)</p>
                            <p><strong>Superficie:</strong> {logement.superficie} m²</p>
                            <p><strong>Nombre de colocataires:</strong> {logement.nbrColoc}</p>
                            <p><strong>Description:</strong> {logement.description}</p>
                            <p><strong>Type:</strong> {logement.type}</p>
                            <p><strong>Ville:</strong> {logement.ville?.nom}</p>
                            <button style={{margin:"10px"}} onClick={() => setEditMode(true)} className="to-reset">Modifier</button>
                            <button style={{margin:"10px"}} onClick={handleDelete} className="to-reset">Supprimer</button>
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
};
export default OffreDetails;
