import React from 'react';
import { Outlet} from 'react-router-dom';
// import Header from '../components/Header';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import BackToTopButton from '../components/BackToTopButton';


const Layout = () => {

  return (
  <>
    {/* <Header /> */}
    <Navigation />
    <div className='reactContent'>
      <Outlet />
    </div>
    <Footer />
    <BackToTopButton/>
  </>
  );
};

export default Layout;
