import React, { useState, useEffect, useRef } from 'react';

const toastStyle = {
  position: 'fixed',
  top: '20px',
  right: '20px',
  backgroundColor: 'white',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  borderRadius: '4px',
  overflow: 'hidden',
  width: '300px',
  zIndex: 1000,
};

const titleStyle = {
  backgroundColor: '#ff545a',
  color: 'white',
  padding: '10px',
  fontWeight: 'bold',
};

const descStyle = {
  padding: '10px',
  color: '#333',
};

const progressBarStyle = {
  height: '4px',
  backgroundColor: '#ddd',
};

const progressStyle = {
  height: '100%',
  backgroundColor: '#ff545a',
  transition: 'width linear',
};

const Toast = ({ title, desc, delay, onClose }) => {
  const [width, setWidth] = useState(100);
  const progressRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    progressRef.current.style.transition = `width ${delay}ms linear`;
    timerRef.current = setTimeout(() => {
      setWidth(0);
    }, 100);

    const timer = setTimeout(() => {
      if (onClose) onClose();
    }, delay);

    return () => {
      clearTimeout(timerRef.current);
      clearTimeout(timer);
    };
  }, [delay, onClose]);

  return (
    <div style={toastStyle}>
      <div style={titleStyle}>{title}</div>
      <div style={descStyle}>{desc}</div>
      <div style={progressBarStyle}>
        <div 
          ref={progressRef}
          style={{...progressStyle, width: `${width}%`}}
        />
      </div>
    </div>
  );
};

export default Toast;
